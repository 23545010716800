import React from "react";

function USP() {
  return (
    <div className="py-12">
      <div className="xl:px-24 pt-5 pb-12 mx-auto">
        <div className="text-4xl xl:text-6xl font-extrabold text-primary-p text-center mb-12">
          USP
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 xl:gap-20 justify-center items-center px-5">
          <div className="flex justify-center">
            <img
              alt=""
              src="/assets/graphics/OrangeCup.svg"
              className="-m-16"
            />
          </div>
          <div className="bg-primary-g flex flex-col gap-5 pb-12 pt-8 ">
            <div className="self-center flex-shrink-0 w-8 h-8 bg-primary-p rounded-full"></div>
            <div className="xl:px-12 text-2xl max-w-sm mx-auto text-primary-p font-semibold leading-loose flex flex-col">
              <span className="whitespace-nowrap"> Over 12000+</span>
              <span className="whitespace-nowrap"> Kadak Chai</span>
              <span className="whitespace-nowrap"> served till date.</span>
            </div>
          </div>
          <div className="bg-primary-g flex flex-col gap-5 pb-12 pt-8">
            <div className="self-center flex-shrink-0 w-8 h-8 bg-primary-p rounded-full"></div>
            <div className="xl:px-12 text-2xl max-w-sm mx-auto text-primary-p font-semibold leading-loose flex flex-col">
              <span className="whitespace-nowrap"> Over 80% of current</span>
              <span className="whitespace-nowrap"> store sale is through</span>
              <span className="whitespace-nowrap"> repeat customer.</span>
            </div>
          </div>
          <div className="bg-primary-g flex flex-col gap-5 pb-12 pt-8">
            <div className="self-center flex-shrink-0 w-8 h-8 bg-primary-p rounded-full"></div>
            <div className="xl:px-12 text-2xl max-w-sm mx-auto text-primary-p font-semibold leading-loose flex flex-col">
              <span className="whitespace-nowrap"> Average Store size</span>
              <span className="whitespace-nowrap"> from 300 - 1000</span>
              <span className="whitespace-nowrap"> square feet.</span>
            </div>
          </div>
          <div className="bg-primary-g flex flex-col gap-5 pb-12 pt-8">
            <div className="self-center flex-shrink-0 w-8 h-8 bg-primary-p rounded-full"></div>
            <div className="xl:px-12 text-2xl max-w-sm mx-auto text-primary-p font-semibold leading-loose flex flex-col">
              <span className="whitespace-nowrap"> Masala Chai - mix of</span>
              <span className="whitespace-nowrap"> 20 ayurvedic herbs</span>
              <span className="whitespace-nowrap"> with no low acidity.</span>
            </div>
          </div>
          <div className="bg-primary-g flex flex-col gap-5 pb-12 pt-8">
            <div className="self-center flex-shrink-0 w-8 h-8 bg-primary-p rounded-full"></div>
            <div className="xl:px-12 text-2xl max-w-sm mx-auto text-primary-p font-semibold leading-loose flex flex-col">
              <span className="whitespace-nowrap"> Menu changed every</span>
              <span className="whitespace-nowrap"> 3 months to focus on</span>
              <span className="whitespace-nowrap"> trending concepts.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default USP;
