import React from "react";

function BestSellerCard({ name, src }) {
  return (
    <div className="focus:outline-none flex-shrink-0 w-64 sm:w-72 lg:w-80 xl:w-96">
      <div className="flex flex-col items-center gap-3 md:gap-5 lg:gap-8 p-5 bg-white rounded-xl border">
        <img
          alt=""
          src={src}
          className="h-64 sm:h-72 lg:h-80 xl:h-96 w-full flex-shrink-0 rounded-xl bg-black object-cover object-center"
        />
        <div className="text-center text-xl md:text-2xl text-primary-p">
          {name}
        </div>
      </div>
    </div>
  );
}

export default BestSellerCard;
