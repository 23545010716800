import React, { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import GalleryCard from "../../components/GalleryCard";
const galleryImages = {
  outlet: [
    {
      src: "/assets/images/outlet/faridabad/1.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/2.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/3.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/4.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/5.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/6.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/7.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/8.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/9.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/10.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/11.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/12.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/faridabad/13.jpeg",
      title: "Faridabad",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/GK1/1.jpg",
      title: "GK1",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/GK1/2.jpg",
      title: "GK1",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/GK1/3.jpg",
      title: "GK1",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/GK1/4.jpg",
      title: "GK1",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/GK1/5.jpg",
      title: "GK1",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/GK1/6.jpg",
      title: "GK1",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/GK1/7.jpg",
      title: "GK1",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/greenPark/1.jpg",
      title: "Green Park",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/greenPark/2.jpg",
      title: "Green Park",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/greenPark/3.jpg",
      title: "Green Park",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/greenPark/4.jpg",
      title: "Green Park",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/greenPark/5.jpg",
      title: "Green Park",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/1.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/2.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/3.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/4.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/5.jpeg",
      title: "NSP, Pitampura",
      type: "outlet",
    },

    // {
    //   src: "/assets/images/outlet/PaschimVihar/6.jpeg",
    //   title: "Paschim Vihar",
    //   type: "outlet",
    // },

    {
      src: "/assets/images/outlet/PaschimVihar/7.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/8.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/9.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/10.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    // {
    //   src: "/assets/images/outlet/PaschimVihar/11.jpeg",
    //   title: "Paschim Vihar",
    //   type: "outlet",
    // },

    // {
    //   src: "/assets/images/outlet/PaschimVihar/12.jpeg",
    //   title: "Paschim Vihar",
    //   type: "outlet",
    // },

    {
      src: "/assets/images/outlet/PaschimVihar/13.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },
    {
      src: "/assets/images/outlet/greaterNoida/01.jpeg",
      title: "Greater Noida",
      type: "outlet",
    },
  ],
  product: [
    {
      src: "/assets/images/products/afganimomo.JPG",
      title: "Afgani Momo",
      type: "product",
    },
    {
      src: "/assets/images/products/AlooMatarSamosa.jpg",
      title: "AlooMatar Samosa",
      type: "product",
    },
    {
      src: "/assets/images/products/alooparantha.jpg",
      title: "Aloo Parantha",
      type: "product",
    },
    {
      src: "/assets/images/products/bakedcheesenachoos.JPG",
      title: "Baked Cheese Nachoos",
      type: "product",
    },
    {
      src: "/assets/images/products/bakednachoos.JPG",
      title: "Baked Nachoos",
      type: "product",
    },
    {
      src: "/assets/images/products/BobaVanillaIceCream.jpg",
      title: "Boba Vanilla IceCream",
      type: "product",
    },
    {
      src: "/assets/images/products/BunMaska.jpg",
      title: "Bun Maska",
      type: "product",
    },
    {
      src: "/assets/images/products/bunnachickensamosa.JPG",
      title: "Bunna Chicken Samosa",
      type: "product",
    },
    {
      src: "/assets/images/products/butterchickenmaggie.jpg",
      title: "Butter Chicken Maggie",
      type: "product",
    },
    {
      src: "/assets/images/products/ButterMomo.jpg",
      title: "Butter Momo",
      type: "product",
    },
    {
      src: "/assets/images/products/cheetinedsamosa.JPG",
      title: "Cheetined Samosa",
      type: "product",
    },
    {
      src: "/assets/images/products/chessyfries.JPG",
      title: "Chessy Fries",
      type: "product",
    },
    {
      src: "/assets/images/products/ChickenSamosa.jpg",
      title: "Chicken Samosa",
      type: "product",
    },
    {
      src: "/assets/images/products/choclateshake.JPG",
      title: "Choclate Shake",
      type: "product",
    },
    {
      src: "/assets/images/products/DalMakhani+Parantha.jpg",
      title: "DalMakhani Parantha",
      type: "product",
    },
    {
      src: "/assets/images/products/DesiKahwaTea.jpg",
      title: "Desi Kahwa Tea",
      type: "product",
    },
    {
      src: "/assets/images/products/HoneyGingerLemon.JPG",
      title: "Honey Ginger Lemon",
      type: "product",
    },
    {
      src: "/assets/images/products/kullardchai.JPG",
      title: "Kullard Chai",
      type: "product",
    },
    {
      src: "/assets/images/products/kurkeramomo.JPG",
      title: "Kurkure Momo",
      type: "product",
    },
    {
      src: "/assets/images/products/kurkuremomo.JPG",
      title: "Kurkure Momo",
      type: "product",
    },
    {
      src: "/assets/images/products/lahoribunnasamosa.JPG",
      title: "Lahori Bunna Samosa",
      type: "product",
    },
    {
      src: "/assets/images/products/MachurianMaggie.jpg",
      title: "Machurian Maggie",
      type: "product",
    },
    {
      src: "/assets/images/products/maharajabreakfasttea.jpg",
      title: "Maharaja Breakfast Tea",
      type: "product",
    },
    {
      src: "/assets/images/products/margaritapizza.JPG",
      title: "Margarita Pizza",
      type: "product",
    },
    {
      src: "/assets/images/products/MetabolismTea.jpg",
      title: "Metabolism Tea",
      type: "product",
    },
    {
      src: "/assets/images/products/omeletteburger.jpg",
      title: "Omelette Burger",
      type: "product",
    },
    {
      src: "/assets/images/products/OrganicPeach.jpg",
      title: "Organic Peach",
      type: "product",
    },
    {
      src: "/assets/images/products/RoseBobaTea.jpg",
      title: "Rose Boba Tea",
      type: "product",
    },
    {
      src: "/assets/images/products/RoseFaludaShake.jpg",
      title: "Rose Faluda Shake",
      type: "product",
    },
    {
      src: "/assets/images/products/simplemaggie(1).JPG",
      title: "Simple Maggie",
      type: "product",
    },
    {
      src: "/assets/images/products/SimpleMaggie.jpg",
      title: "Simple Maggie",
      type: "product",
    },
    {
      src: "/assets/images/products/topping.JPG",
      title: "Topping",
      type: "product",
    },
    {
      src: "/assets/images/products/tripleschewezenrice.JPG",
      title: "Triple Schewezen Rice",
      type: "product",
    },
    {
      src: "/assets/images/products/TurmericGingerTea.jpg",
      title: "Turmeric Ginger Tea",
      type: "product",
    },
    {
      src: "/assets/images/products/VadaPav.jpg",
      title: "Vada Pav",
      type: "product",
    },
    {
      src: "/assets/images/products/vegafganimomo.JPG",
      title: "Veg Afghani Momo",
      type: "product",
    },
    {
      src: "/assets/images/products/vegafghanimomo.JPG",
      title: "Veg Afghani Momo",
      type: "product",
    },
    {
      src: "/assets/images/products/veggiedelight.jpg",
      title: "Veggie Delight",
      type: "product",
    },
    {
      src: "/assets/images/products/vegpakoda.jpg",
      title: "Veg Pakoda",
      type: "product",
    },
  ],
  all: [
    {
      src: "/assets/images/products/VadaPav.jpg",
      title: "Vada Pav",
      type: "product",
    },
    {
      src: "/assets/images/products/vegafganimomo.JPG",
      title: "Veg Afghani Momo",
      type: "product",
    },
    {
      src: "/assets/images/products/vegafghanimomo.JPG",
      title: "Veg Afghani Momo",
      type: "product",
    },
    {
      src: "/assets/images/products/veggiedelight.jpg",
      title: "Veggie Delight",
      type: "product",
    },
    {
      src: "/assets/images/products/vegpakoda.jpg",
      title: "Veg Pakoda",
      type: "product",
    },
    {
      src: "/assets/images/outlet/PaschimVihar/4.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/6.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/7.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/8.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/9.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/10.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },

    {
      src: "/assets/images/outlet/PaschimVihar/11.jpeg",
      title: "Paschim Vihar",
      type: "outlet",
    },
  ],
  moments: [
    {
      src: "/assets/images/moments/01.jpg",
      title: "Customer Moments",
      type: "moments",
    },
    {
      src: "/assets/images/moments/02.jpg",
      title: "Customer Moments",
      type: "moments",
    },
    {
      src: "/assets/images/moments/03.jpg",
      title: "Customer Moments",
      type: "moments",
    },
    {
      src: "/assets/images/moments/04.jpg",
      title: "Customer Moments",
      type: "moments",
    },

    {
      src: "/assets/images/moments/05.jpg",
      title: "Customer Moments",
      type: "moments",
    },

    {
      src: "/assets/images/moments/06.jpg",
      title: "Customer Moments",
      type: "moments",
    },

    {
      src: "/assets/images/moments/07.jpg",
      title: "Customer Moments",
      type: "moments",
    },

    {
      src: "/assets/images/moments/08.jpg",
      title: "Customer Moments",
      type: "moments",
    },

    {
      src: "/assets/images/moments/09.jpg",
      title: "Customer Moments",
      type: "moments",
    },

    {
      src: "/assets/images/moments/10.jpg",
      title: "Customer Moments",
      type: "moments",
    },

    {
      src: "/assets/images/moments/11.jpg",
      title: "Customer Moments",
      type: "moments",
    },
    {
      src: "/assets/images/moments/12.jpg",
      title: "Customer Moments",
      type: "moments",
    },
    {
      src: "/assets/images/moments/13.jpg",
      title: "Customer Moments",
      type: "moments",
    },
    {
      src: "/assets/images/moments/14.jpg",
      title: "Customer Moments",
      type: "moments",
    },
    {
      src: "/assets/images/moments/15.jpg",
      title: "Customer Moments",
      type: "moments",
    },
    {
      src: "/assets/images/moments/16.jpg",
      title: "Customer Moments",
      type: "moments",
    },
    {
      src: "/assets/images/moments/17.jpg",
      title: "Customer Moments",
      type: "moments",
    },
  ],
  coverage: [
    {
      src: "/assets/images/products/VadaPav.jpg",
      title: "Vada Pav",
      type: "product",
    },
    {
      src: "/assets/images/products/vegafganimomo.JPG",
      title: "Veg Afghani Momo",
      type: "product",
    },
    {
      src: "/assets/images/products/vegafghanimomo.JPG",
      title: "Veg Afghani Momo",
      type: "product",
    },
    {
      src: "/assets/images/products/veggiedelight.jpg",
      title: "Veggie Delight",
      type: "product",
    },
    {
      src: "/assets/images/products/vegpakoda.jpg",
      title: "Veg Pakoda",
      type: "product",
    },
  ],
};
// function Filter({ images, type, children }) {
//   const filtered = useMemo(() => {
//     if (type) {
//       return images.filter((el) => el.type === type);
//     }
//     return images;
//   }, [images, type]);
//   return children(filtered);
// }
function GalleryImages() {
  const [filter, setFilter] = useState("all");
  return (
    <div className="my-12 w-full max-w-screen-2xl mx-auto">
      <div className="flex justify-center">
        <ScrollContainer className="flex gap-12 text-primary-r text-2xl px-5 whitespace-nowrap">
          <button
            onClick={(e) => {
              e.preventDefault();
              setFilter("all");
            }}
            className={`font-semibold ${
              filter === "all" ? "text-primary-u" : ""
            }`}
          >
            All
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setFilter("product");
            }}
            className={`font-semibold ${
              filter === "product" ? "text-primary-u" : ""
            }`}
          >
            Product Gallery
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setFilter("outlet");
            }}
            className={`font-semibold ${
              filter === "outlet" ? "text-primary-u" : ""
            }`}
          >
            Outlet Gallery
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setFilter("moments");
            }}
            className={`font-semibold ${
              filter === "moments" ? "text-primary-u" : ""
            }`}
          >
            Customer Moments
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setFilter("coverage");
            }}
            className={`font-semibold ${
              filter === "coverage" ? "text-primary-u" : ""
            }`}
          >
            Blogger/media Coverage
          </button>
        </ScrollContainer>
      </div>
      {/* <Filter images={[]} type={filter}>
        {(images) => { */}
      {/* return ( */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 my-12 px-5">
        {galleryImages[filter].map(({ src, title, type }) => (
          <GalleryCard
            {...{ src, title, type, key: src }}
            className="w-full h-72 md:h-96 xl:h-80"
          />
        ))}
      </div>
      {/* ); */}
      {/* }} */}
      {/* </Filter> */}
    </div>
  );
}

export default GalleryImages;
