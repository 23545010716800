import React, { useState } from "react";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import Hero from "./Hero";
import MenuSectionCard from "./MenuSectionCard";

const menu = {
  drinks: {
    bestsellers: [],
    sections: [
      {
        heading: "HOT FRESH CHAI ",
        sub_heading: "(Sugar/ Dhoodh/ Patti)",
        image: "/assets/images/bestseller/adrakElachiTea.jpg",
        items: [
          {
            heading: "TRADITIONAL BOMBAY CUTTING CHAI",
            sub_heading: "",
            description: "",
            price: "39/59",
          },
          {
            heading: "CHOCOLATE CHAI",
            sub_heading: "",
            description: "",
            price: "39/59",
          },
          {
            heading: "PAAN CHAI",
            sub_heading: "",
            description: "",
            price: "49/69",
          },
          {
            heading: "DESI CHAI",
            sub_heading:
              "(TULSI/ADRAK/ELAICHI/KESAR/SAUNF/LOUNG/PUDINA/CINNAMON)- choose your add on",
            description: "",
            price: "49/69",
          },
          {
            heading: "ADRAK ELAICHI CHAI",
            sub_heading: "",
            description: "soothing cup of kadak chai",
            price: "49/69",
          },
          {
            heading: "MASALA CHAI",
            sub_heading: "",
            description:
              "a mix of 20 indian herbs with hand picked assam tea leaves. Low on acidity",
            price: "49/69",
          },
          {
            heading: "DIGESTION CHAI",
            sub_heading: "",
            description:
              "a secretive blend of 8 indian herbs and assam tea which helps in digestion",
            price: "49/69",
          },
          {
            heading: "RELAX CHAI",
            sub_heading: "",
            description:
              "a secretive blend of 5 indian herbs and assam tea which helps you destress/ relax",
            price: "49/69",
          },
          {
            heading: "NAWABI CHAI",
            sub_heading: "",
            description:
              "Badam, Khus Khus, Elaichi with crushed rose petals topped with amul butter - Truly Royal!",
            price: "69/89",
          },
          {
            heading: "HERBAL DELIGHT CHAI",
            sub_heading: "",
            description:
              "A blend of 19 herbs and spices to detox your body and mind.  Zero Caffeine.",
            price: "69/89",
          },
        ],
      },
      {
        heading: "FLAVOURED ICED CHAI",
        sub_heading: "",
        image: "/assets/images/bestseller/EspressoColdBrew.jpg",
        items: [
          {
            heading: "HONEY GREEN TEA",
            sub_heading: "",
            description: "",
            price: "129",
          },
          {
            heading: "LEMON ICED TEA",
            sub_heading: "",
            description: "",
            price: "129",
          },
          {
            heading: "JASMINE GREEN/BLACK TEA",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "PASSIONFRUIT GREEN/BLACK TEA",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "STRAWBERRY GREEN/BLACK TEA",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "MANGO GREEN/BLACK TEA",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "PEACH GREEN/ BLACK TEA",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "MASALA CHAI LATTE",
            sub_heading: "",
            description: "",
            price: "169",
          },
          {
            heading: "GREEN TEA LATTE",
            sub_heading: "",
            description: "",
            price: "169",
          },
        ],
      },
      {
        heading: "FLAVOURED MILK BUBBLE TEAS",
        sub_heading: "",
        image: "/assets/images/bestseller/MatchaBobaTea.jpg",
        items: [
          {
            heading: "CLASSIC BOBA TEA",
            sub_heading: "",
            description: "",
            price: "179",
          },
          {
            heading: "WATERMELON STRAWBERRY BOBA TEA",
            sub_heading: "",
            description: "",
            price: "199",
          },
          {
            heading: "ROSE BOBA TEA",
            sub_heading: "",
            description: "",
            price: "199",
          },
          {
            heading: "LITCHI COCONUT BOBA TEA",
            sub_heading: "",
            description: "",
            price: "199",
          },
          {
            heading: "GREEN MATCHA BOBA TEA",
            sub_heading: "",
            description: "",
            price: "199",
          },
          {
            heading: "DOUBLE GOLDEN BOBA TEA",
            sub_heading: "",
            description: "",
            price: "199",
          },
          {
            heading: "HAZELNUT BOBA TEA",
            sub_heading: "",
            description: "",
            price: "199",
          },
        ],
      },
      {
        heading: "UNCHAI",
        sub_heading: "(11 AM ONWARDS)",
        image: "/assets/images/bestseller/Thandai.jpg",
        items: [
          {
            heading: "MASALA SIKANJI",
            sub_heading: "",
            description: "",
            price: "129",
          },
          {
            heading: "CLASSIC MINT MOJITO",
            sub_heading: "",
            description: "",
            price: "129",
          },
          {
            heading: "HOMEMADE THANDAI",
            sub_heading: "(MADE WITH SPECIAL INHOUSE THANDAI SYRUP)",
            description: "",
            price: "129",
          },
          {
            heading: "FILTER COFFEE",
            sub_heading: "",
            description: "",
            price: "99",
          },
          {
            heading: "MARSHMALLOW HOT CHOCOLATE",
            sub_heading: "",
            description: "",
            price: "109",
          },
          {
            heading: "CLASSIC COLD COFFEE",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "ICE CREAM COLD COFFEE",
            sub_heading: "",
            description: "",
            price: "179",
          },
          {
            heading: "STRAWBERRY YAKULT",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "MANGO YAKULT",
            sub_heading: "",
            description: "",
            price: "149",
          },
        ],
      },
      {
        heading: "SHAKE",
        sub_heading: "",
        image: "/assets/images/products/choclateshake.JPG",
        items: [
          {
            heading: "OREO SHAKE",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "ROSE FALUDA SHAKE",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "BANANA SHAKE",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "MANGO SHAKE",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "BROWNIE FUDGE SHAKE",
            sub_heading: "",
            description: "",
            price: "169",
          },
          {
            heading: "5 STAR SHAKE",
            sub_heading: "",
            description: "",
            price: "169",
          },
        ],
      },
      {
        heading: "TOPPINGS",
        sub_heading: "",
        image: "/assets/images/products/topping.JPG",
        items: [
          {
            heading: "TAPIOCA PEARLS",
            sub_heading: "(BLACK/ GOLDEN/MOJITO)",
            description: "",
            price: "69",
          },
          {
            heading: "FRUIT JELLY",
            sub_heading: "(ALOE VERA/ BROWN SUGAR/RAINBOW/LITCHI/COCONUT)",
            description: "",
            price: "49",
          },
          {
            heading: "COFFEE JELLY",
            sub_heading: "",
            description: "",
            price: "49",
          },
          {
            heading: "BURSTING BOBA",
            sub_heading: "",
            description: "",
            price: "49",
          },
          {
            heading: "HONEY TWIGS",
            sub_heading: "",
            description: "",
            price: "9",
          },
        ],
      },
      {
        heading: "BEVERAGES",
        sub_heading: "",
        image: "/assets/images/products/AlooMatarSamosa.jpg",
        items: [
          {
            heading: "HIMALAYA MINERAL WATER",
            sub_heading: "",
            description: "",
            price: "60",
          },
        ],
      },
    ],
  },
  food: {
    bestsellers: [],
    sections: [
      {
        heading: "Nashta",
        sub_heading: "",
        image: "/assets/images/products/VadaPav.jpg",
        items: [
          {
            heading: "Bun Maska ",
            sub_heading: "",
            description: "",
            price: "79",
          },
          {
            heading: "Bun Bujia",
            sub_heading: "",
            description: "",
            price: "89",
          },
          {
            heading: "Bun Samosa",
            sub_heading: "",
            description: "",
            price: "89",
          },
          {
            heading: "Bun Omellette",
            sub_heading: "",
            description: "",
            price: "69",
          },
          {
            heading: "Keema Pav (Soya/ Chicken)",
            sub_heading: "",
            description: "",
            price: "89",
          },
          {
            heading: "Aamchi Vada Pav",
            sub_heading: "",
            description: "",
            price: "89",
          },
          {
            heading: "Ghar ka Poha",
            sub_heading: "",
            description: "",
            price: "99",
          },
          {
            heading: "Garlic Bread (Plain/ Cheese/ Chilly Cheese)",
            sub_heading: "",
            description: "",
            price: "119/129",
          },
          {
            heading: "Fries (Salted/ Masala)",
            sub_heading: "",
            description: "",
            price: "119",
          },
          {
            heading: "Fries (Cheese Loaded/Peri Peri/Makhani)",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "Za’atar dusted Mix vegetables pakodas",
            sub_heading: "",
            description: "",
            price: "129",
          },
          {
            heading: "Daryaganj chicken pakodas",
            sub_heading: "",
            description: "",
            price: "149",
          },
        ],
      },
      {
        heading: "Samosas",
        sub_heading: "",
        image: "/assets/images/products/AlooMatarSamosa.jpg",
        items: [
          {
            heading: "Amritsari Aloo matar",
            sub_heading: "",
            description: "",
            price: "29",
          },
          {
            heading: "Punjabi Tadka ",
            sub_heading: "",
            description: "",
            price: "39",
          },
          {
            heading: "Soya Keema ",
            sub_heading: "",
            description: "",
            price: "49",
          },
          {
            heading: "Chilly Chicken",
            sub_heading: "",
            description: "",
            price: "69",
          },
          {
            heading: "Lahori Chicken Keema",
            sub_heading: "",
            description: "",
            price: "69",
          },
        ],
      },
      {
        heading: "Combos",
        sub_heading: "",
        image: "/assets/images/products/cheetinedsamosa.JPG",
        items: [
          {
            heading: "Veg Tikki Burger with Fries",
            sub_heading: "",
            description: "",
            price: "99",
          },
          {
            heading: "Aloo Pyaaz Parantha ",
            sub_heading: "",
            description: "",
            price: "99",
          },
          {
            heading: "Egg Parantha",
            sub_heading: "",
            description: "",
            price: "109",
          },
          {
            heading: "Mix Sauce Pasta (Veg/ Non Veg)",
            sub_heading: "",
            description: "",
            price: "199/229",
          },
          {
            heading: "Dal Makhani + Malabari Paratha",
            sub_heading: "",
            description: "",
            price: "139",
          },
          {
            heading: "Desi Pav Bhaji",
            sub_heading: "",
            description: "",
            price: "129",
          },
          {
            heading: "Mini Idli + Sambar + Coconut Chutney",
            sub_heading: "",
            description: "",
            price: "129",
          },
          {
            heading: "Chola + Rice",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "Rajma + Rice",
            sub_heading: "",
            description: "",
            price: "149",
          },
        ],
      },
      {
        heading: "Naan Pizza ",
        sub_heading: "",
        image: "/assets/images/products/margaritapizza.JPG",
        items: [
          {
            heading: "Margarita",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "Veggie Delight",
            sub_heading: "",
            description: "",
            price: "179",
          },
          {
            heading: "Kadhai Paneer",
            sub_heading: "",
            description: "",
            price: "179",
          },
          {
            heading: "Soya keema ",
            sub_heading: "",
            description: "",
            price: "179",
          },
          {
            heading: "Butter Chicken ",
            sub_heading: "",
            description: "",
            price: "199",
          },
        ],
      },
      {
        heading: "Maggie ",
        sub_heading: "",
        image: "/assets/images/products/SimpleMaggie.jpg",
        items: [
          {
            heading: "Simple Maggie",
            sub_heading: "",
            description: "",
            price: "99",
          },
          {
            heading: "Tadka Maggi",
            sub_heading: "",
            description: "",
            price: "129",
          },
          {
            heading: "Extra Masala Maggie",
            sub_heading: "",
            description: "",
            price: "129",
          },
          {
            heading: "Hot Garlic Maggie",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "Butter Chicken Maggie",
            sub_heading: "",
            description: "",
            price: "169",
          },
        ],
      },
      {
        heading: "Momos  (6 pcs)",
        sub_heading: "",
        image: "/assets/images/products/vegafganimomo.JPG",
        items: [
          {
            heading: "Pizza Momo ",
            sub_heading: "",
            description: "",
            price: "89",
          },
          {
            heading: "Palak Paneer Momo ",
            sub_heading: "",
            description: "",
            price: "99",
          },
          {
            heading: "Palak Paneer Momo (Wheat)",
            sub_heading: "",
            description: "",
            price: "109",
          },
          {
            heading: "Chicken Momo ",
            sub_heading: "",
            description: "",
            price: "129",
          },
          {
            heading: "Chicken Momo (Wheat)",
            sub_heading: "",
            description: "",
            price: "139",
          },
          {
            heading: "Afghani Momo (Veg/ Non Veg)",
            sub_heading: "",
            description: "",
            price: "129/149",
          },
          {
            heading: "Tandoori Momo (Veg/ Non Veg)",
            sub_heading: "",
            description: "",
            price: "129/149",
          },
          {
            heading: "Butter Chicken Momo",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "Pan Fried Momo (Paneer/ Chicken)",
            sub_heading: "",
            description: "",
            price: "149",
          },
        ],
      },
      {
        heading: "Multi Grain Sandwich",
        sub_heading: "",
        image: "/assets/images/products/vegpakoda.jpg",
        items: [
          {
            heading: "Spinach Corn",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "Bombay Style",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "Paneer Makhani",
            sub_heading: "",
            description: "",
            price: "179",
          },
          {
            heading: "Smoked Chicken ",
            sub_heading: "",
            description: "",
            price: "179",
          },
          {
            heading: "Butter Chicken",
            sub_heading: "",
            description: "",
            price: "179",
          },
        ],
      },
      {
        heading: "Roti Wraps",
        sub_heading: "",
        image: "/assets/images/products/DalMakhani+Parantha.jpg",
        items: [
          {
            heading: "Egg",
            sub_heading: "",
            description: "",
            price: "129",
          },
          {
            heading: "Soya Keema",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "Kadhai Paneer",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "Chilly Chicken",
            sub_heading: "",
            description: "",
            price: "169",
          },
        ],
      },
      {
        heading: "Meeta",
        sub_heading: "",
        image: "/assets/images/products/BobaVanillaIceCream.jpg",
        items: [
          {
            heading: "Double Chocolate Brownie",
            sub_heading: "",
            description: "",
            price: "149",
          },
          {
            heading: "Belgian Fudge Brownie",
            sub_heading: "",
            description: "",
            price: "169",
          },
          {
            heading: "Black Forest Cake",
            sub_heading: "",
            description: "",
            price: "179",
          },
          {
            heading: "Red Velvet Cake",
            sub_heading: "",
            description: "",
            price: "219",
          },
        ],
      },
    ],
  },
};

function Menu() {
  // const a = new Wor
  const [type, setType] = useState("drinks");
  return (
    <div
      className="bg-blend-lighten bg-opacity-80 bg-primary-q flex flex-col min-h-screen overflow-hidden"
      style={{
        backgroundImage: `url(/assets/graphics/Background.svg)`,
      }}
    >
      <Header />

      <main className="flex-grow">
        <Hero />
        <div className="flex gap-16 justify-center my-16">
          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
                setType("drinks");
              }}
              className={`${
                type === "drinks" ? "text-primary-u" : " text-primary-r"
              } text-3xl font-semibold`}
            >
              Beverage Menu
            </button>
          </div>
          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
                setType("food");
              }}
              className={`${
                type === "food" ? "text-primary-u" : " text-primary-r"
              } text-3xl font-semibold`}
            >
              Food Menu
            </button>
          </div>
        </div>
        <div className="flex flex-col max-w-screen-2xl mx-auto my-16 gap-12">
          {menu[type].sections.map((section) => {
            return <MenuSectionCard {...section} />;
          })}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Menu;
