import React from "react";

function Hero() {
  return (
    <div className="bg-primary-a">
      <div className="max-w-screen-2xl xl:px-24 pt-5 pb-12 mx-auto">
        <div className="flex justify-around w-full px-5 items-center">
          <div className="flex flex-col gap-3 text-primary-p">
            <div className="text-6xl font-extrabold">Menu</div>
            <div className="mt-5">
              <a
                href="https://chailelo.dotpe.in/order"
                target="_blank"
                rel="noreferrer"
                className="rounded-lg xl:rounded-2xl text-white bg-primary-f text-xl md:text-2xl xl:text-3xl px-5 md:px-8 xl:px-12 py-2 xl:py-3 font-light"
              >
                Order Now
              </a>
            </div>
          </div>
          <img
            alt=""
            src="/assets/graphics/orange BOOK.svg"
            className="w-1/3 flex-shrink"
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;
