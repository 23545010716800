import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import GalleryCard from "./../../components/GalleryCard";

function Gallery() {
  return (
    <div className="">
      <div className="pt-5 pb-12 mx-auto">
        <div className="text-4xl xl:text-6xl font-extrabold text-primary-p text-center mb-5 xl:mb-12">
          Gallery
        </div>
        {/* <Slider
          dots={false}
          className=""
          arrows={false}
          // slidesPerRow={3}
          variableWidth={true}
          slidesToScroll={1}
          slidesToShow={1}
        > */}
        <ScrollContainer className="flex gap-5 px-5">
          {/* <div className="flex gap-8 my-12 w-full"> */}
          {[
            {
              src: "/assets/images/outlet/faridabad/4.jpeg",
              title: "Faridabad",
              type: "outlet",
            },
            {
              src: "/assets/images/outlet/GK1/3.jpg",
              title: "GK1",
              type: "outlet",
            },
            {
              src: "/assets/images/outlet/greenPark/5.jpg",
              title: "Green Park",
              type: "outlet",
            },
            {
              src: "/assets/images/outlet/PaschimVihar/6.jpeg",
              title: "Paschim Vihar",
              type: "outlet",
            },
          ].map(({ title, src, type }) => {
            return <GalleryCard src={src} title={title} type={type} />;
          })}
          {/* </div> */}
        </ScrollContainer>
        {/* </Slider> */}
        <div className="flex justify-center mt-5 xl:mt-12">
          <button className="rounded-lg xl:rounded-2xl text-white bg-primary-f text-xl md:text-2xl xl:text-3xl px-5 md:px-8 xl:px-12 py-2 xl:py-3 font-light">
            Show More
          </button>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
