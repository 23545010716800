import React from "react";
import MenuItem from "./MenuItem";

function MenuSectionCard({ heading, sub_heading, image, items }) {
  return (
    <div
      className="flex flex-col lg:grid grid-cols-12 bg-transparent mx-5 bg-black lg:bg-transparent lg:max-h-180"
      style={{
        // maxHeight: "45rem",
        borderTopLeftRadius: "60px",
        borderTopRightRadius: "60px",
      }}
    >
      <div
        className=" col-span-4 lg:bg-black p-12 flex flex-col max-h-full text-primary-v"
        style={{ borderTopLeftRadius: "60px" }}
      >
        <div className="text-3xl font-bold text-center lg:text-left">
          {heading}
        </div>
        <div className="text-2xl font-semibold text-center lg:text-left">
          {sub_heading}
        </div>
        <div className="flex max-h-64 lg:max-h-96 flex-grow justify-center">
          <img
            alt="item preview"
            src={image}
            className="max-h-full object-contain object-center"
          />
        </div>
      </div>
      <div className="flex flex-col overflow-y-auto col-span-8 bg-white shadow max-h-180">
        {items.map((item) => {
          return <MenuItem {...item} />;
        })}
      </div>
    </div>
  );
}

export default MenuSectionCard;
