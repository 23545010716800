import React from "react";

function VisitUs() {
  return (
    <div className="">
      <div className="max-w-screen-2xl xl:px-24 pt-5 pb-12 mx-auto">
        <div className="text-4xl xl:text-6xl font-extrabold text-primary-p text-center mb-12">
          VISIT US
        </div>
        <div className="flex flex-col justify-center gap-20 md:gap-8 px-5">
          {[
            {
              city: "Faridabad - Haryana",
              FSSAI_NO: "20821003000222",
              address1: "BOOTH NO-37, SECTOR-16, HUDA MARKET , ",
              address2: "Faridabad Town, Faridabad, Haryana-122002 ",
              phone: "012-94875222",
              embed:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.293664605769!2d77.31753281542589!3d28.41039450085007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cddb2be606791%3A0xfdd6cdcd5a619c80!2sChai%20Lelo!5e0!3m2!1sen!2sin!4v1630146357160!5m2!1sen!2sin",
            },
            {
              city: "Connaught Place - Delhi ",
              FSSAI_NO: "23321010001867",
              address1: "1/34 M Block Middle Circle Connaught Place, ",
              address2: "South East, CHANKYA PURI, New Delhi, Delhi - 110001 ",
              phone: "011-41635365",
              embed:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.9037191624716!2d77.22056651542917!3d28.63264759082588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfdf10676ba37%3A0xcc92238ab1e78632!2sChai%20lelo!5e0!3m2!1sen!2sin!4v1630146390903!5m2!1sen!2sin",
            },
            {
              city: "Greater Kailash 1 - Delhi",
              FSSAI_NO: "23320010001948",
              address1: "A-3 PAMPOSH ENCLAVE, GREATER KAILASH-1, ",
              address2:
                "NEAR NEHRU PLACE , BAHA PUR, KALKAJI, South East, Delhi-110048 ",
              phone: "011-79682783",
              embed:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.8183228883286!2d77.24493941542794!3d28.545179994779186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3341a025de9%3A0x7e4be10a8cfda152!2sChai%20lelo%20-%20Chai%20Cafe!5e0!3m2!1sen!2sin!4v1630146320501!5m2!1sen!2sin",
            },
            {
              city: "NSP - Delhi",
              FSSAI_NO: "23321001000026",
              address1: "UNIT NO. G-3 AND G-4, G/F, AGGARWAL CYBER PLAZA, ",
              address2:
                "PLOT NO. C-4, 5 AND 6, NSP, PITAMPURA, SARASWATI VIHAR, North West, Delhi-110034 ",
              phone: "7428782773",
              embed:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.865922825208!2d77.14799041543019!3d28.69365708806198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d033a566fdf2d%3A0xf1b006940ce51331!2sChai%20Lelo%20-%20NSP!5e0!3m2!1sen!2sin!4v1630146340467!5m2!1sen!2sin",
            },

            {
              city: "Sector 2 Noida (Delivery Only) - Uttar Pradesh ",
              FSSAI_NO: "22721922000460",
              address1: "A-91, Sector-2, Noida, ",
              address2: "Gautam Budh Nagar, Uttar Pradesh - 201301",
              phone: "",
              embed:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.444927017531!2d77.31145831542851!3d28.586426292916286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5135af0e88b%3A0x4c940ea6ce49ee1c!2sChai%20Lelo%20-%20Noida!5e0!3m2!1sen!2sin!4v1630146406543!5m2!1sen!2sin",
            },

            {
              city: "Paschim Vihar - Delhi",
              FSSAI_NO: "23321006000136",
              address1: "SHOP NO-21, PASCHIM PURI, GOURMENT HUB, ",
              address2: "PEERA GARHI, PUNJABI BAGH, West, Delhi-110063 ",
              phone: "011-42658272",
              embed:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.722511594088!2d77.10907901542976!3d28.668026189223834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03b2b7a69a13%3A0x1cca459189d99af8!2sChai%20Lelo%20-%20Paschim%20Vihar!5e0!3m2!1sen!2sin!4v1630146295858!5m2!1sen!2sin",
            },

            {
              city: "Green Park - Delhi",
              FSSAI_NO: "13321002000438",
              address1: "S-1 G/F GREEN PARK EXTN, ",
              address2: "South, Delhi-110016",
              phone: "011-40541851",
              embed:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.3709366185667!2d77.20266441542807!3d28.558622044172314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce37cad9e6501%3A0xdfda0d456eef1b25!2sChai%20Lelo%20X%20Hookah%20Craft!5e0!3m2!1sen!2sin!4v1630146223695!5m2!1sen!2sin",
            },
            {
              city: "Ghitorni (Delivery Only) - Delhi ",
              FSSAI_NO: "23321002001368",
              address1: "KHASRA NO-645, SHOP IN GROUND GHITORNI,",
              address2: "CHAHTAR PUR, SAKET, South  , Delhi - 110030 ",
              phone: "",
            },
            {
              city: "Central Plaza Gurgaon - Gurugram",
              FSSAI_NO: "30210603108165437",
              address1: "G-01, Central Plaza, Sector 53, ",
              address2: "Counter No. 9, Gurugram, 122002",
              phone: "",
              embed:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.216186097682!2d77.10017657902442!3d28.4428988024601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1902e6cbb59d%3A0x632ed487b5574c4!2sChai%20Lelo%20-%20Gurugram!5e0!3m2!1sen!2sin!4v1631224435266!5m2!1sen!2sin",
            },
            {
              city: "Grand Venice Greater Noida - Uttar Pradesh ",
              FSSAI_NO: "22721442000295",
              address1:
                "THE GRAND VENICE, FC-15, 4TH FLOOR, PLOT NO-SH3, SITE-4, ",
              address2:
                "NEAR PARI CHOWK, GREATER NOIDA , KASNA, GautamBudh Nagar Tahsil-II, Uttar Pradesh - 201308 ",
              embed:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56126.35096870002!2d77.49114707910157!3d28.45252310000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc15e6c34c81d%3A0xf4316ca0b628bd5f!2sChai%20Lelo%20-%20Greater%20Noida!5e0!3m2!1sen!2sin!4v1631224556933!5m2!1sen!2sin",
            },
            {
              city: "Gurgaon M3M - Gurugram",
              FSSAI_NO: "To be applied",
              address1: "M3M Cornerwalk, Sector-74, ",
              address2: "Gurugram",
              phone: "",
            },
          ].map(
            ({ city, address1, address2, pincode, FSSAI_NO, embed, phone }) => {
              return (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 text-xl items-center">
                  <div>
                    <div className="text-2xl mb-5 font-semibold">{city}</div>
                    <div>{address1}</div>
                    <div>{address2}</div>
                    <div>{pincode}</div>
                    <div>{phone}</div>
                    <div className="mt-2">{`FSSAI no - ${FSSAI_NO}`}</div>
                  </div>

                  <div
                    className="w-full overflow-hidden bg-none border"
                    style={{
                      height: "250px",
                    }}
                  >
                    <iframe
                      title={address1}
                      style={{
                        height: "250px",
                      }}
                      allowfullscreen=""
                      loading="lazy"
                      width="100%"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      src={embed}
                    ></iframe>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}

export default VisitUs;
