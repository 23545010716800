import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";
// import MyCarousel from "./../../components/MyCarousel";
function Hero() {
  return (
    <div className="bg-primary-a">
      <div className="max-w-screen-2xl xl:px-24 pt-5 pb-12 mx-auto">
        <Carousel
          // onNextEnd={({ index, object, ...prop }, currentPageIndex) => {
          //   console.log(
          //     "{ index,object,...prop},currentPageIndex",
          //     { index, object, prop },
          //     currentPageIndex
          //   );
          //   // clearTimeout(resetTimeout);
          //   // if (index + 1 === totalPages) {
          //   //   resetTimeout = setTimeout(() => {
          //   //     carouselRef.current.goTo(0);
          //   //   }, 1500); // same time
          //   // }
          // }}
          showArrows={false}
          renderPagination={({ activePage, onClick, pages }) => {
            return (
              <div className="flex items-center justify-center gap-3 w-full">
                {pages.map((page, i) => (
                  <div
                    className="cursor-pointer border-4 rounded-full border-primary-m w-5 md:w-8 h-5 md:h-8 flex items-center justify-center"
                    onClick={() => {
                      onClick(page);
                    }}
                  >
                    <span
                      className={`${
                        page === activePage ? "" : "invisible"
                      } rounded-full w-2 md:w-3.5 h-2 md:h-3.5 bg-primary-n transition-all duration-150 ease-in-out`}
                    ></span>
                  </div>
                ))}
              </div>
            );
          }}
          infinite={true}
        >
          <div className="py-12">
            <div className="flex sm:justify-around w-full px-5 items-center">
              <div className="flex flex-col md:gap-3 text-primary-p">
                <div className="text-4xl xl:text-6xl font-extrabold">Chai</div>
                <div className="text-xl md:text-2xl xl:text-3xl">
                  [chaa-ae] • noun
                </div>
                <div className="text-xl md:text-2xl xl:text-3xl font-medium mt-2 xl:mt-5">
                  A solution for any emotion.
                </div>
                <div className="mt-2 xl:mt-5">
                  <a
                    href="https://chailelo.dotpe.in/order"
                    target="_blank"
                    rel="noreferrer"
                    className="rounded-lg xl:rounded-2xl text-white bg-primary-f text-xl md:text-2xl xl:text-3xl px-5 md:px-8 xl:px-12 py-2 xl:py-3 font-light"
                  >
                    Order Now
                  </a>
                </div>
              </div>
              <img
                draggable={false}
                alt=""
                src="/assets/images/mascot/mascot.png"
                className="w-1/3 flex-shrink hidden sm:block"
              />
            </div>
          </div>
          <div className="py-12">
            <div className="flex md:justify-around w-full px-5 items-center">
              <div className="flex flex-col md:gap-3 text-primary-p">
                <div className=" text-3xl md:text-4xl xl:text-6xl font-extrabold max-w-2xl">
                  We don't just serve traditional Chai
                </div>
                <div className="text-2xl font-medium mt-5">
                  Check out tea flavours from around the world
                </div>
                <div className="mt-5">
                  <a
                    href="https://chailelo.dotpe.in/order"
                    target="_blank"
                    rel="noreferrer"
                    className="rounded-lg xl:rounded-2xl text-white bg-primary-f text-xl md:text-2xl xl:text-3xl px-5 md:px-8 xl:px-12 py-2 xl:py-3 font-light"
                  >
                    Order Now
                  </a>
                </div>
              </div>
              <img
                draggable={false}
                alt=""
                src="/assets/graphics/Boba Tea.svg"
                className="w-1/3 -m-16 flex-shrink hidden md:block"
              />
            </div>
          </div>
          <div className="py-12">
            <div className="flex justify-around w-full px-5 items-center">
              <div className="flex flex-col md:gap-3 text-primary-p">
                <div className=" text-3xl md:text-4xl xl:text-6xl font-extrabold max-w-2xl">
                  Catch up with your friends
                </div>

                <div className="mt-5">
                  <Link
                    to="/contactus"
                    className="rounded-lg xl:rounded-2xl text-white bg-primary-f text-xl md:text-2xl xl:text-3xl px-5 md:px-8 xl:px-12 py-2 xl:py-3 font-light"
                  >
                    Locate Store
                  </Link>
                </div>
              </div>
              <img
                draggable={false}
                alt=""
                src="/assets/graphics/OrangeCup.svg"
                className="w-1/3 -m-16 flex-shrink hidden md:block"
              />
              <img
                draggable={false}
                alt=""
                src="/assets/graphics/Bunmaska.svg"
                className="w-1/4 -m-16 flex-shrink hidden md:block"
              />
            </div>
          </div>
          <div className="py-12">
            <div className="flex justify-around w-full px-5 items-center">
              <div className="flex flex-col md:gap-3 text-primary-p">
                <div className=" text-3xl md:text-4xl xl:text-6xl font-extrabold max-w-md leading-tight">
                  Hungry after Chai?
                </div>
                <div className="text-2xl font-medium ">
                  Try out other dishes
                </div>
                <div className="mt-3">
                  <Link
                    to="/menu"
                    className="rounded-lg xl:rounded-2xl text-white bg-primary-f text-xl md:text-2xl xl:text-3xl px-5 md:px-8 xl:px-12 py-2 xl:py-3 font-light"
                  >
                    Show Menu
                  </Link>
                </div>
              </div>
              <img
                draggable={false}
                alt=""
                src="/assets/graphics/Sandwich.svg"
                className="w-1/3 -m-16 flex-shrink"
              />
              <img
                draggable={false}
                alt=""
                src="/assets/graphics/Burger.svg"
                className="w-1/3 -m-16 flex-shrink"
              />
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default Hero;
