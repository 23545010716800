import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import BestSellerCard from "../../components/BestSellerCard";
function BestSellers() {
  return (
    <div className="">
      <div className="xl:px-24 pb-12 mx-auto">
        <div className="text-4xl xl:text-6xl font-extrabold text-primary-p text-center my-8 md:my-10 xl:my-12">
          Bestsellers
        </div>
        {/* <Slider
          dots={false}
          className=""
          arrows={false}
          // slidesPerRow={3}
          variableWidth={true}
          slidesToScroll={1}
          slidesToShow={1}
        > */}
        <ScrollContainer className="scroll-container flex gap-3 px-5">
          <div className="flex gap-3 overflow-x-visible">
            {[
              {
                name: "Adrak Elaichi Chai",
                src: "/assets/images/bestseller/adrakElachiTea.jpg",
              },
              {
                name: "Chocolate Tea",
                src: "/assets/images/bestseller/ChocolateTea.jpg",
              },
              {
                name: "Nawabi Chai",
                src: "/assets/images/bestseller/NawabiChai.jpg",
              },
              {
                name: "Matcha Boba Tea",
                src: "/assets/images/bestseller/MatchaBobaTea.jpg",
              },
              {
                name: "Triple Schzewan Rice",
                src: "/assets/images/products/tripleschewezenrice.JPG",
              },
            ].map(({ name, src }) => (
              <BestSellerCard name={name} src={src} />
            ))}
          </div>
        </ScrollContainer>
        {/* </Slider> */}
        <div className="flex justify-center mt-12">
          <button className="rounded-lg xl:rounded-2xl text-white bg-primary-f text-xl md:text-2xl xl:text-3xl px-5 md:px-8 xl:px-12 py-2 xl:py-3 font-light">
            View Full Menu
          </button>
        </div>
      </div>
    </div>
  );
}

export default BestSellers;
