import React from "react";
// import Slider from "react-slick";
// import NextArrow from "../../components/NextArrow";
// import PrevArrow from "../../components/PrevArrow";
import Carousel from "react-elastic-carousel";

const comments = [
  {
    name: "Sush Dingra",
    comment: `A small and cozy chai cafe in CP. 
I have tried so many food items at chai Lelo including their famous adrak chai and cold coffee with ice cream. 
It was an amazing experience and the food was absolutely delicious especially their butter chicken naan pizza was fantastic. `,
  },
  {
    name: "Megha",
    comment: `Delicious food, amazing service, cozy interior, and aesthetic ambiance is a perfect description of my experience. Our visit was worth it and all dishes were amazing. Overall, it was a wonderful experience. Highly recommended!`,
  },
  {
    name: "Akshima",
    comment: `The food here is amazing both in terms of taste and hygiene. It was well packed. The delivery was also speedy. It was totally worth the money!`,
  },
  {
    name: "Vikram Patil",
    comment: `Small & petite place to chill, work, and munch on goodies. Hospitable staff (Laxmi), kudos to her for running a fair show. Coming back here again.`,
  },
  {
    name: "Sakshi Jain",
    comment: `A few days back I visited this awesome place.
Overall it's a nice cafe, the ambiance was super awesome and the food is superb, yummy, and mouthwatering!
You guys should also visit their place for their yummy and tempting food, wonderful hospitality, and lovely hosts.`,
  },
  {
    name: "Yatin Arya",
    comment: `Perfect place for chai lovers. The bubble tea was very refreshing. Naan pizza and momos were delicious too. Overall it was a value for money experience for me. Highly recommended!`,
  },
  {
    name: "Nikita",
    comment: `Spectacular food, diverse menu, and wonderful ambiance makes the experience worth it. The service is quick and perfect. We had an amazing time. Highly recommended!`,
  },
  {
    name: "Rishika Arya",
    comment: `It was one of the best dine-in experiences. Fresh food, good staff, quick service, and perfect ambiance have made it a value for money experience for me. The shakes in particular were my favorite. Highly recommended!`,
  },
  {
    name: "Sanjana Verma",
    comment: `Want best shakes? I suggest you should go to this place honestly it's amazing. I love the quality of food and hygiene of this place. The shakes are my favorite specifically chocolate. I mean it was so good that I enjoyed every sip of it ❤️💯 #mustvisit`,
  },
  {
    name: "Utsa Madan",
    comment: `Very aesthetic place
Offers a great variety of tea & along with some yummy munching options like pizzas 🍕
Do try their Naan Pizza 🍕`,
  },
  {
    name: "Akshata Aggarwal",
    comment: `Chai Lelo is a great place to grab a chai with some delicious snacks. We tried their naanza, baked Maggi and snacks platter. The baked cheese Maggi was simply amazing. The naanza was cheesy with lovely toppings. The snacks platter had all our favorites and we thoroughly enjoyed it with kulhad chai and iced tea! The interiors are cute with board games available too!`,
  },
  {
    name: "Naveen Arya",
    comment: `Visited this cute outlet a few days back.
As the name suggests, they've got a wide variety of tea.
Tried their chai pakoda, platter which contains pakode, jumbo pizza slice, fries, samosa, which was enough for one person.
They've both indoor and outdoor seating options.
You can definitely try this place, the quality and taste are really good.`,
  },
  {
    name: "Aditya",
    comment: `Recently I visited Chai lelo, Green park. The food was good and worth spending money on. I tried their Masala Chai with maska bun, Cheese Maggi, and Palak paneer momo. I loved the ambiance, good for enjoying a cup of Chai. The service was on time and the staff was helpful.`,
  },
  {
    name: "Diksha Bhatia",
    comment: `A very cute little cafe located in Green Park. The food offerings are really delicious. Samosa, Pasta, Pizza, Baked Cheese Maggi, Kullad wali Chai and much more..the place serves small yet wholesome bites. My personal favorite was their Cheese Baked Maggi. Do try this for sure with a Glass of Tea.`,
  },
  {
    name: "Amisha Singh",
    comment: `Went to this place yesterday. It has a simple yet cozy ambiance. I was here to try out their snacks along with chai. I had maggi, chaat, momos and samose. Everything was tasty and absolutely worth it. Tandoori momos were too good. The prices were very reasonable. This place seemed perfect to me for hanging out with your friends.`,
  },
  {
    name: "Sahil Rao",
    comment: `Recently I ordered bhel puri Mumbai style from this outlet to my place and it was fabulous I genuinely loved it and it was delivered on time and in a great sealed packing
Quantity was enough for two.
It was very tangy and spicy I truly loved it and I want to give it 5/5 stars from my side
I truly found it value for money.`,
  },
  {
    name: "Sonali Shastri",
    comment: `The place is located in green park.. ambiance is very good and interior decoration is also good and colorful pizza momos were good and the mojito was yummy.`,
  },
];

function Testimonials() {
  return (
    <div className="bg-primary-h">
      <div className="max-w-screen-2xl xl:px-24 pt-5 pb-12 mx-auto">
        <Carousel

        // fade={true}
        // autoplay={true}
        // dots={false}
        // infinite={true}
        // speed={1500}
        // pauseOnHover={true}
        // arrows={true}
        // nextArrow={<NextArrow />}
        // prevArrow={<PrevArrow />}
        >
          {comments.map(({ name, comment }) => {
            return (
              <div className="">
                <div className="flex flex-col justify-center w-full px-5 items-center gap-5 py-12">
                  <img
                    alt=""
                    draggable={false}
                    src="/assets/images/mascot/mascot.png"
                    className="rounded-full w-40 h-40 flex-shrink-0 border object-cover object-center bg-transparent"
                  />

                  <div className=" text-xl text-center whitespace-pre-wrap">
                    {comment}
                  </div>
                  <div className="flex justify-center my-5">
                    <div className="p-1 w-12 bg-black rounded-full"></div>
                  </div>
                  <div className="text-3xl font-semibold text-center">
                    {name}
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}

export default Testimonials;
