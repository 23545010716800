import React from "react";
import { Link } from "react-router-dom";

function Story() {
  return (
    <div className="w-full p-5 bg-primary-i py-16">
      <div className="grid grid-cols-7 max-w-screen-2xl mx-auto justify-center items-center">
        <div className="w-full col-span-3 justify-end pr-5 hidden sm:flex">
          <img
            alt=""
            src="/assets/graphics/orange BOOK.svg"
            className="md:w-3/4"
          />
        </div>
        <div className="flex flex-col col-span-7 sm:col-span-4">
          <div>
            <div className="text-4xl xl:text-6xl font-extrabold text-primary-p text-left mb-5 xl:mb-12">
              Our Delicious Story
            </div>
            <div className="text-lg xl:text-2xl max-w-2xl font-light leading-relaxed">
              Chai lelo aims at creating beautiful community places across India
              where people can relax away from home enjoying their varied tea
              culture along with fresh chai snacks to lift their mood and forget
              their worries. All our outlets are lined by planter which are
              recycled from plastic bottle hand painted by backward children’s
              and planted with Snake plants to boost oxygen levels in our cafes.
            </div>
          </div>
          <Link
            className="rounded-lg xl:rounded-2xl text-white bg-primary-f text-xl md:text-2xl xl:text-3xl px-5 md:px-8 xl:px-12 py-2 xl:py-3 font-light mt-5 self-start"
            to="/about"
          >
            Know More
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Story;
