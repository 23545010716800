import React from "react";

function VisionMission() {
  return (
    <div className="flex flex-col w-full my-12 gap-5">
      <div className="flex flex-col sm:flex-row sm:h-72 flex-shrink-0 justify-center bg-white">
        <div className="w-48 sm:w-3/12 md:w-5/12 text-primary-p text-3xl lg:text-4xl font-extrabold text-right p-8 md:p-16 flex items-center justify-end self-center">
          <div>VISION</div>
        </div>
        <div className="sm:w-9/12 md:w-7/12 bg-primary-j px-12 text-xl text-primary-p font-medium flex items-center">
          <div>To awaken our country everyday with a fresh kadak chai.</div>
        </div>
      </div>
      <div className="flex flex-col-reverse sm:flex-row sm:h-72 flex-shrink-0 justify-center items-stretch bg-white">
        <div className="sm:w-9/12 md:w-7/12 bg-primary-j px-12 text-xl text-primary-p font-medium flex items-center">
          <div>
            To reach to every section of the society to create the most
            satisfying experience with their family and friends by providing
            quality and affordable products from around the country.
          </div>
        </div>
        <div className="w-48 sm:w-3/12 md:w-5/12 text-primary-p text-3xl lg:text-4xl font-extrabold text-right p-8 md:p-16 flex items-center justify-end self-center">
          <div>MISSION</div>
        </div>
      </div>
    </div>
  );
}

export default VisionMission;
