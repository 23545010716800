import React from "react";
import Header from "./../../components/Header";
import Hero from "./Hero";
import Footer from "./../../components/Footer";
import FounderMessage from "./FounderMessage";
import VisionMission from "./VisionMission";
import Outlets from "./Outlets";

function AboutUs() {
  return (
    <div
      className="bg-blend-lighten bg-opacity-80 bg-primary-q flex flex-col min-h-screen overflow-hidden"
      style={{
        backgroundImage: `url(/assets/graphics/Background.svg)`,
      }}
    >
      <Header />

      <main className="flex-grow">
        <Hero />
        <section className="my-12">
          <div className="max-w-screen-lg text-center text-3xl text-primary-o font-bold mx-auto">
            Chai Lelo is a new generation brand encompassing all the facid of
            tea drinking culture from around the world coupled with a lip
            smacking street food options.
          </div>
          <div className="max-w-screen-2xl mx-auto shadow flex flex-col md:flex-row bg-primary-g mt-12 items-center p-8 md:p-12  gap-5 md:gap-12">
            <img
              src="/assets/images/mascot/mascot.png"
              className="bg-transparent w-1/2"
              alt=""
            />
            <div className="text-center font-semibold text-xl">
              Chai lelo aims at creating beautiful community places across India
              where people can relax away from home enjoying their varied tea
              culture along with fresh chai snacks to lift their mood and forget
              their worries. All our outlets are lined by planter which are
              recycled from plastic bottle hand painted by backward children’s
              and planted with Snake plants to boost oxygen levels in our cafes.
            </div>
          </div>
        </section>
        <section>
          <div className="max-w-screen-2xl mx-auto flex items-center flex-col-reverse md:flex-row">
            <div className="p-12 font-semibold text-xl">
              Chai lelo keeps tracking new markets and trends and innovate its
              menu every 3 months to bring fresh ingredients and taste to our
              loyal customers.
            </div>
            <img
              src="/assets/graphics/Orangecup_market.svg"
              className="w-1/3"
              alt=""
            />
          </div>
        </section>
        <FounderMessage />
        <VisionMission />
        <Outlets />
      </main>
      <Footer />
    </div>
  );
}

export default AboutUs;
