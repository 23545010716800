import React, { useState } from "react";

function CSRCard({ images = [], logo, content }) {
  const [current, setCurrent] = useState(0);
  return (
    <div className="p-5 sm:p-12 lg:p-8 xl:p-16 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center bg-primary-w">
      <div className="flex flex-col gap-8 items-center">
        <img src={logo} alt="logo" className="w-48 h-48 rounded-full border" />
        <div className="text-3xl leading-relaxed">{content}</div>
      </div>
      <div className="flex flex-col gap-5">
        <div>
          <img
            alt="img"
            src={images[current]}
            className="w-full object-cover object-center h-110 hidden md:block"
          />
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-3">
          {images.map((img, index) => {
            return (
              <img
                alt="img"
                src={img}
                className={`w-full h-56 sm:h-40 object-cover object-center cursor-pointer ${
                  current === index ? "md:border-8 border-primary-l" : ""
                }`}
                onClick={() => {
                  setCurrent(index);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CSRCard;
