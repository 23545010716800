import React from "react";
import ContactForm from "../../components/ContactForm";

function ContactSection() {
  return (
    <div className="p-8 max-w-screen-2xl mx-auto flex flex-col sm:flex-row justify-around items-center bg-white my-12 gap-5">
      <div className="flex flex-col items-center justify-center w-full gap-5">
        <img
          alt=""
          src="/assets/graphics/OrangeCup_phone.svg"
          className="md:w-2/3 -m-16 flex-shrink hidden sm:block"
          style={{ transform: `scaleX(-1)` }}
        />
        <div className="text-4xl xl:text-6xl font-extrabold text-primary-p">
          Contact Us
        </div>
      </div>
      <div className="w-full flex justify-center">
        <ContactForm />
      </div>
    </div>
  );
}

export default ContactSection;
