import React, { useState } from "react";

function TeamCard({ image, name, designation, content, reverse }) {
  return (
    <div
      className={`flex ${
        reverse
          ? "flex-col items-center md:items-center md:flex-row-reverse"
          : "flex-col items-center md:items-center md:flex-row"
      } gap-12`}
    >
      <img
        src={image}
        alt="logo"
        className="w-64 h-64 flex-shrink-0 rounded-full border object-cover object-center"
      />
      <div className="flex flex-col md:items-start items-center gap-3 bg-primary-w p-8 lg:p-12 ">
        <div className="flex flex-col md:items-start items-center">
          <div className="text-4xl font-bold text-center">{name}</div>
          <div className="text-3xl">{designation}</div>
        </div>
        <div className="text-xl">{content}</div>
      </div>
    </div>
  );
}

export default TeamCard;
