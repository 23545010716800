import React from "react";

function FeaturedOn() {
  return (
    <div className="">
      <div className="max-w-screen-2xl pt-5 pb-12 mx-auto">
        <div className="text-4xl xl:text-6xl font-extrabold text-primary-p text-center mb-12">
          Featured On
        </div>
        <div className="flex flex-wrap justify-around gap-2 px-5">
          <img
            alt=""
            src="/assets/images/featuredon/aoa.png"
            className="object-contain object-center h-48"
          />

          <img
            alt=""
            src="/assets/images/featuredon/gmaps.png"
            className="object-contain object-center h-48"
          />

          <img
            alt=""
            src="/assets/images/featuredon/swiggy.png"
            className="object-contain object-center h-48"
          />

          <img
            alt=""
            src="/assets/images/featuredon/zomato.png"
            className="object-contain object-center h-48"
          />
        </div>
      </div>
    </div>
  );
}

export default FeaturedOn;
