import React from "react";

function FounderMessage() {
  return (
    <div className="">
      <div className="pt-5 pb-12 mx-auto">
        <div className="text-6xl font-extrabold text-primary-p text-center mb-12">
          Message From The Founder
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col items-center md:items-stretch md:grid grid-cols-12">
            <div className="col-span-5 flex justify-end md:mb-8 mr-8">
              <div className="md:w-4/5 p-0 flex flex-col xl:flex-row border bg-white gap-5">
                <img
                  className="h-80 xl:h-full w-full xl:w-56 object-cover object-center"
                  src="/assets/images/team/founder.png"
                  alt="founder"
                />
                <div className="flex flex-col text-primary-p px-5 pb-5 xl:pt-5 font-semibold text-xl">
                  <span className="text-2xl font-bold">Archit Singhal</span>
                  <span>Co-Founder & CEO,</span>
                  <span>Chai Lelo</span>
                </div>
              </div>
            </div>
            <div className="col-span-7 bg-primary-g px-5 py-8 flex items-center">
              <div className="w-11/12 text-lg leading-relaxed">
                The search for this started when we visited Vietnam and saw a
                very different tea drinking culture brewing. Tea was not limited
                to hot milk but there were lot of other forms of enjoying teas.
                Hence the quest to broaden the horizon for tea started. There
                was a very popular culture which was spreading around the world-
                Bubble tea and it was very common staple for lot of working
                class middle class in Eastern countries. I tried approaching a
                one of largest tea chain from Taiwan to open in India but the
                terms and condition baffled me that created a need for India to
                have local brand rather than copy pasting a brand from abroad.
              </div>
            </div>
          </div>

          <div className="flex flex-col-reverse items-center md:items-stretch md:grid grid-cols-12">
            <div className="col-span-5 bg-primary-g pr-5 py-8 flex justify-end items-center">
              <div className="md:w-4/5 p-5 md:p-0 text-lg leading-relaxed">
                Chai Lelo came as a very nostalgic experience in our childhood
                where often we used to hear this word when we are on the road by
                our beloved Tea street vendor. I still remember my experience in
                Mumbai when our evening fatigue used to lower as soon as our
                office chai wala announcing his arrival by saying ‘ Chai Lelo’.
                In every changing world we have lost the meaning of tea left
                along how to brew it properly. Even though Tea is most drinking
                beverage after Water in the world, we still have to learn to
                embrace this liquid.
              </div>
            </div>
            <div className="col-span-7 flex mt-8 ml-8">
              <div className="border p-5 bg-white flex-col gap-8">
                <div className="">
                  <video
                    controls
                    controlsList="nodownload"
                    width={500}
                    height={500}
                  >
                    <source
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/houzie-b645a.appspot.com/o/chai-lelo%2Fvideos%2Ffounder_message.mp4?alt=media&token=c6c88e5d-9f01-4b62-afb4-feab505da5ee"
                      }
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="flex flex-col text-primary-p font-bold text-xl mt-5">
                  <span>Archit Singhal</span>
                  <span>Co-Founder & CEO, Chai Lelo</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FounderMessage;
