import React from "react";
import LazyLoad from "react-lazyload";

const icons = {
  product: "/assets/icons/Heart.svg",
  media: "/assets/icons/Coverage.svg",
  outlet: "/assets/icons/Location.svg",
  moments: "/assets/icons/Star.svg",
};

function GalleryCard({
  src,
  title,
  type,
  className = "h-72 md:h-96 w-72 md:w-96",
}) {
  return (
    <div
      className={`flex relative ${className} border bg-cover bg-center flex-shrink-0`}
      // style={{ backgroundImage: `url(${src})` }}
    >
      <LazyLoad once={true} offset={100}>
        <img
          src={src}
          alt=""
          className="absolute object-cover object-center inset-0 h-full w-full"
        />
      </LazyLoad>
      <div className="absolute bottom-0 inset-x-0 w-full bg-white rounded-t-3xl flex px-8 py-5 gap-8 items-center shadow-md z-10">
        <img src={icons[type]} alt="" className="w-6 h-6" />
        <div>{title}</div>
      </div>
    </div>
  );
}

export default GalleryCard;
