import React, { useState } from "react";
import EventsWeddingsCard from "./EventsWeddingsCard";
const data = {
  images: [
    "/assets/images/tpoint/01.JPG",
    "/assets/images/tpoint/02.JPG",
    "/assets/images/tpoint/03.JPG",
    "/assets/images/tpoint/04.JPG",
    "/assets/images/tpoint/05.JPG",
    "/assets/images/tpoint/06.JPG",
    "/assets/images/tpoint/07.JPG",
    "/assets/images/tpoint/08.JPG",
    "/assets/images/tpoint/09.JPG",
    "/assets/images/tpoint/10.JPG",
    "/assets/images/tpoint/11.JPG",
    "/assets/images/tpoint/12.JPG",
    "/assets/images/tpoint/13.JPG",
    "/assets/images/tpoint/14.JPG",
    "/assets/images/tpoint/15.JPG",
    "/assets/images/tpoint/16.JPG",
    "/assets/images/tpoint/17.JPG",
    "/assets/images/tpoint/18.JPG",
    "/assets/images/tpoint/19.JPG",
    "/assets/images/tpoint/20.JPG",
    "/assets/images/tpoint/21.JPG",
  ],
  logo: "/assets/images/mascot/mascot.png",

  content: `T- Point  -Open Mic Event Hosted by Chai Lelo GK1
T-Point- An attempt empowered by @chailelo_india is an endeavour for artists to come together while people can enjoy the beauty that art adds to  our lives! 
Let's bring back the joy of a conversation shared, a song hummed together, a few strings of music and a trail of happy memories. 
You can come with your friends, just enjoy or register as a performer.
For artists, this is your space to explore, enjoy the beauty of living your performance.
We had over 200 people audience and over 20 new artist performance to a power packed venue at GK1 on a rainy Sunday evening.`,
};
//   ,
//   {
//     images: ["/assets/images/tpoint/01.JPG"],

//     content: `24x7 Lounge for Wedding & Corporate Events`,
//   },
// ];
function Main() {
  const { content, logo, images } = data;
  const [current, setCurrent] = useState(0);
  return (
    <div className="flex flex-col gap-20 md:gap-24 max-w-screen-2xl mx-auto px-5 my-20 md:my-32">
      <div className="p-5 sm:p-12 lg:p-8 xl:p-16 flex flex-col-reverse lg:grid grid-cols-1 lg:grid-cols-2 gap-12 items-start bg-primary-w">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-3">
          {images.map((img, index) => {
            return (
              <img
                alt="img"
                src={img}
                className={`w-full h-56 sm:h-40 object-cover object-center cursor-pointer ${
                  current === index ? "md:border-8 border-primary-l" : ""
                }`}
                onClick={() => {
                  setCurrent(index);
                }}
              />
            );
          })}
        </div>

        <div className="flex flex-col-reverse lg:flex-col gap-5">
          <div>
            <img
              alt="img"
              src={images[current]}
              className="w-full object-cover object-center h-110 hidden md:block"
            />
          </div>
          <div className="flex flex-col gap-8 items-center">
            <img
              src={logo}
              alt="logo"
              className="w-48 h-48 rounded-full border"
            />
            <div className="text-3xl leading-relaxed">{content}</div>
          </div>
        </div>
      </div>
      <div className="p-5 sm:p-12 lg:p-8 xl:p-16 flex flex-col lg:flex-row  gap-12 items-start bg-primary-w w-full items-center">
        <div className="flex flex-col gap-8 items-center">
          <img
            src={logo}
            alt="logo"
            className="w-48 h-48 rounded-full border"
          />
          <div className="text-3xl leading-relaxed text-center">
            {"24x7 Lounge for Wedding & Corporate Events"}
          </div>
        </div>
        <div>
          <video controls controlsList="nodownload" width={500} height={500}>
            <source
              src={
                "https://firebasestorage.googleapis.com/v0/b/houzie-b645a.appspot.com/o/chai-lelo%2Fvideos%2Fevents_and_weddings.mp4?alt=media&token=a963da32-1625-49a9-9bbf-9688325dd11d"
              }
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
}

export default Main;
