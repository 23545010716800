import React from "react";
import TeamCard from "./TeamCard";
const csr = [
  {
    image: "/assets/images/team/founder.png",
    name: "Archit Singhal",
    designation: "Founder",
    content:
      "Archit has completed his BCOM (H), MBA Finance and is a CFA charter as well. He is currently pursuing his senior management course from IIM Lucknow. He has over 6 years of professional experience as investment banker with EY/ BDO/ leading brokerage houses/ AMC’s . As a entrepreneur he has gained over 6 years experience in hospitality and event business. He is born and raised in New Delhi. He is married and has one daughter.",
  },

  {
    image: "/assets/images/team/aman.jpeg",
    name: "Aman Mehra",
    designation: "Director",
    content:
      "Aman has over 20 years in experience in Finance and Real Estate industry. He helps in locking new real estate and take care of all finance decision for the company.",
  },
  {
    image: "/assets/images/team/garima.jpeg",
    name: "Garima Mehra",
    designation: "Director",
    content:
      "Garima has rich experience in Interior designing and Backend Support. She helps in supervising all the interior work at all the outlets.",
  },
  {
    image: "/assets/images/team/lalit.jpeg",
    name: "Lalit Gupta",
    designation: "Head - Accounts & Finance",
    content:
      "Lalit Gupta is Chartered Accountant, having 15 years of experience and worked for organization of big repute like Jubilant Food works (Dominos), OYO Rooms, ITC, HSIL, Kohler etc, Lalit take care of all Accounts and Finance related activities and streamlines the processes around the activities.",
  },
];
function Main() {
  return (
    <div className="flex flex-col gap-16 md:gap-16 max-w-screen-2xl mx-auto px-5 my-20 md:my-32">
      {/* <div className="text-4xl xl:text-6xl font-extrabold text-primary-p text-center mb-12">
        Meet Our Team
      </div> */}

      {csr.map(({ image, name, designation, content }, index) => {
        return (
          <TeamCard
            {...{ image, name, designation, content, reverse: index % 2 === 1 }}
          />
        );
      })}
    </div>
  );
}

export default Main;
