import React from "react";
import CSRCard from "./CSRCard";
const csr = [
  {
    images: [
      "/assets/images/csr/01.jpeg",
      "/assets/images/csr/02.jpeg",
      "/assets/images/csr/03.jpeg",
      "/assets/images/csr/04.jpeg",
      "/assets/images/csr/05.jpeg",
      "/assets/images/csr/06.jpeg",
    ],
    logo: "/assets/images/mascot/mascot.png",

    content:
      "Chai lelo is a by product for Voice for Local and creating a social conscious brand which respond to the community it serves in.",
  },
  {
    images: [
      "/assets/images/csr/07.jpeg",
      "/assets/images/csr/08.jpeg",
      "/assets/images/csr/09.jpeg",
      "/assets/images/csr/10.jpeg",
      "/assets/images/csr/11.jpeg",
      "/assets/images/csr/12.jpeg",
      "/assets/images/csr/13.jpeg",
      "/assets/images/csr/14.jpeg",
    ],
    logo: "/assets/images/mascot/mascot.png",

    content:
      "Chai lelo was the only QSR brand in India which converted all their kitchen into seva kitchen during the second wave of corona pandemic and delivered over 25000 meals in 45 days across Delhi to covid effected people. Our team worked even harder during the pandemic to serve everyone in need for home cooked style food.",
  },
  {
    images: [
      "/assets/images/csr/15.jpeg",
      "/assets/images/csr/16.jpeg",
      "/assets/images/csr/17.jpeg",
      "/assets/images/csr/18.jpeg",
      "/assets/images/csr/19.jpeg",
      "/assets/images/csr/20.jpeg",
      "/assets/images/csr/21.jpeg",
      "/assets/images/csr/22.jpeg",
      "/assets/images/csr/23.jpeg",
      "/assets/images/csr/24.jpeg",
      "/assets/images/csr/25.jpeg",
      "/assets/images/csr/26.jpeg",
      "/assets/images/csr/27.jpeg",
      "/assets/images/csr/28.jpeg",
      "/assets/images/csr/29.jpeg",
    ],
    logo: "/assets/images/mascot/mascot.png",

    content:
      "Post Pandemic we collaborated with a leading publishing house to distribute books on gaining mental health - Tenali Way to all our customer to spread positivity.",
  },
];
function Main() {
  return (
    <div className="flex flex-col gap-20 md:gap-24 max-w-screen-2xl mx-auto px-5 my-20 md:my-32">
      {csr.map(({ content, logo, images }) => {
        return <CSRCard {...{ content, logo, images }} />;
      })}
    </div>
  );
}

export default Main;
